<template>
  <BaseModal
    cancel-button-text="Meldung ausblenden"
    header="Export des Betreuungsaktes gestartet"
    modal-name="BetreuungsaktExportStartedModal"
    :show-submit-button="false"
  >
    <span>
      Die Betreuungsakt-Exportdatei wird gerade für den Download vorbereitet.<br>
      Je nachdem, wie viele Eintr&auml;ge im Betreuungsakt der Sch&uuml;lerin / des Sch&uuml;lers vorhanden sind, kann es einen Moment dauern bis der Download der Exportdatei gestartet wird.<br>
      Bitte haben Sie ein wenig Geduld.
    </span>
  </BaseModal>
</template>

<script>
import BaseModal from "../BaseModal";
export default {
  name: "BetreuungsaktExportStartedModal",
  components: {BaseModal},
  data: function () {
    return {
    }
  }
}
</script>

<style scoped>

</style>