<template>
  <sui-modal v-model="isModalOpen">
    <sui-modal-header v-if="header">
      {{ header }}
    </sui-modal-header>
    <sui-modal-content>
      <sui-modal-description>
        <slot />
      </sui-modal-description>
    </sui-modal-content>
    <sui-modal-actions>
      <sui-button
        v-if="showSubmitButton"
        primary
        type="submit"
        @click="emitSubmit"
      >
        {{ submitButtonText }}
      </sui-button>
      <slot name="third-button" />
      <sui-button @click="close">
        {{ cancelButtonText }}
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
import store from "@/store";
import {mapActions} from "vuex";

export default {
  name: "BaseModal",
  props: {
    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },
    header: {
      type: String,
      default: ''
    },
    modalName: {
      type: String,
      required: true,
    },
    submitButtonText: {
      type: String,
      default: 'Submit'
    },
    showSubmitButton: {
      type: Boolean,
      default: true
    }
  },
  emits: ['submit'],
  computed: {
    /**
     * isModalOpen is required here to ensure that the modal is displayed
     * even when using v-if on modals in Home.vue or other components that use modals.
     * I wasn't able to find out the reason for this yet
     */
    isModalOpen() {
      return store.getters['modalsModule/isModalOpen'](this.modalName);
    }
  },
  methods: {
    ...mapActions('modalsModule', ['closeModal']),
    close() {
      this.closeModal(this.modalName);
    },
    emitSubmit() {
      this.$emit('submit');
    }
  }
}
</script>

<style scoped>

</style>