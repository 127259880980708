<template>
  <BaseModal
    cancel-button-text="Abbrechen"
    header="Export des Betreuungsaktes"
    modal-name="BetreuungsaktExportConfigurationModal"
    submit-button-text="Exportdatei erstellen"
    @submit="exportBetreuungsakt"
  >
    <form
      method="POST"
      enctype="multipart/form-data"
    >
      <h4>Bitte wählen Sie die Betreuungsakt-Bereiche aus, die exportiert werden sollen</h4>
      <p>In der Auswahlliste befinden sich nur Betreuungsakt-Bereiche, in denen Einträge erstellt wurden.</p>
      <button
        type="button"
        class="ui button"
        @click="toggleAllBetreuungsaktFunctions(true)"
      >
        Alle auswählen
      </button>
      <button
        type="button"
        class="ui button"
        @click="toggleAllBetreuungsaktFunctions(false)"
      >
        Alle abwählen
      </button>
      <hr>
      <div
        v-for="betreuungsaktFunction in betreuungsaktFunctionsData"
        :key="betreuungsaktFunction.name"
      >
        <BaseCheckbox
          v-model="betreuungsaktFunction.selected"
          :label="getBetreuungsaktFunctionFullName(betreuungsaktFunction.name)"
        />
      </div>
      <div
        v-if="errorMessage"
        class="ui error message"
      >
        <p>{{ errorMessage }}</p>
      </div>
    </form>
  </BaseModal>
</template>

<script>
import BaseCheckbox from "../BaseCheckbox";
import BaseModal from "../BaseModal";
import {mapActions, mapGetters, mapMutations} from "vuex";
import store from "../../store";
export default {
  name: "BetreuungsaktExportConfigurationModal",
  components: {BaseCheckbox, BaseModal},
  data: function () {
    return {
      betreuungsaktFunctionsData: [],
      errorMessage: "",
    }
  },
  computed: {
    ...mapGetters('exportBetreuungsaktModule', {
      getBetreuungsaktExportStudentId: 'getBetreuungsaktExportStudentId',
      getBetreuungsaktExportStudentName: 'getBetreuungsaktExportStudentName',
      getBetreuungsaktExportStudentVorname: 'getBetreuungsaktExportStudentVorname',
    }),
    ...mapGetters('filesModule', {
      getBetreuungsaktFunctionFullName: 'getBetreuungsaktFunctionFullName',
      getBetreuungsaktFunctionsWithEntries: 'getBetreuungsaktFunctionsWithEntries',
    }),
    areAnyBetreuungsaktFunctionsSelected() {
      return this.betreuungsaktFunctionsData.some(betreuungsaktFunction => betreuungsaktFunction.selected);
    }
  },
  created() {
    // Initialize betreuungsaktFunctionsData in created to ensure that the initial state of the checkboxes has been initialized correctly
    // when the toggle function is called.
    this.initializeBetreuungsaktFunctionsData();
  },
  methods: {
    ...mapActions('modalsModule', {
      closeModal: 'closeModal',
      openModal: 'openModal'
    }),
    ...mapMutations('exportBetreuungsaktModule', {
      clearBetreuungsaktExportStudentData: 'clearBetreuungsaktExportStudentData',
    }),
    exportBetreuungsakt() {
      if (!this.areAnyBetreuungsaktFunctionsSelected) {
        alert('Bitte wählen Sie mindestens einen Bereich aus.');
        return;
      }
      
      this.openModal("BetreuungsaktExportStartedModal");
      let dateArray = new Date().toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).split('.');
      // Formats the current date in yyyy-mm-dd
      let current_date = dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
      store.dispatch('filesModule/downloadFile', {
        additionalParams: this.betreuungsaktFunctionsData,
        apiRoute: '/api/schueler/' + this.getBetreuungsaktExportStudentId + '/export-betreuungsakt',
        fileName: 'Betreuungsakt_'+this.getBetreuungsaktExportStudentName+'_'+this.getBetreuungsaktExportStudentVorname+'_'+current_date+'.zip'
      })
      .then(() => {
        this.closeModal("BetreuungsaktExportConfigurationModal");
        this.clearBetreuungsaktExportStudentData();
      })
      .catch((error) => {
        this.closeModal("BetreuungsaktExportStartedModal");
        if (error.message){
          store.dispatch('filesModule/getBetreuungsaktFunctionsWithEntries')
          .then(() => {
            this.initializeBetreuungsaktFunctionsData();
            this.errorMessage = error.message;
          });
        }
        else {
          alert('Fehler beim Erstellen der Betreuungsakt-Exportdatei. Bitte wenden Sie sich an die/den AdministratorIn.');
          this.closeModal("BetreuungsaktExportConfigurationModal");
          this.clearBetreuungsaktExportStudentData();
        }
      });
    },
    initializeBetreuungsaktFunctionsData() {
      const betreuungsaktFunctionsWithEntries = this.getBetreuungsaktFunctionsWithEntries;
      this.betreuungsaktFunctionsData = betreuungsaktFunctionsWithEntries.map(name => ({
        name: name,
        selected: false
      }));
    },
    toggleAllBetreuungsaktFunctions(select) {
      this.betreuungsaktFunctionsData.forEach(betreuungsaktFunction => {
        betreuungsaktFunction.selected = select;
      });
    }
  }
}
</script>

<style scoped>

</style>