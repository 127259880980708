<template>
  <h4 class="centered margins no-margin-bottom margin-top-large">Login</h4>
  <div class="ui segment margins margin-top">
    <form
      class="ui form success error"
      @submit.prevent="login"
    >
      <div class="field">
        <label>E-Mail</label>
        <input
          v-model="loginForm.email"
          type="email"
          required
          placeholder="z.B. john@doe.com"
        >
      </div>
      <div
        class="field"
        style="margin-bottom: 0"
      >
        <label>Passwort</label>
        <input
          v-model="loginForm.password"
          type="password"
          required
          placeholder="z.B. secret*123"
        >
        <div style="text-align: right">
          <div class="password-reset-link">
            <a
              href="#"
              @click="toggleResetPasswordModal"
            >
              <small>
                Passwort vergessen?
              </small>
            </a>
          </div>
        </div>
      </div>
      <div
        v-if="!schoolExists"
        class="field"
      >
        <label>Schulk&uuml;rzel</label>
        <input
          v-model="schoolInput"
          type="text"
          required
          placeholder="z.B. demosfz"
        >
      </div>
      <button
        type="button"
        class="ui fluid basic button button_margins"
        @click="toggleFirstLoginInformationModal"
      >
        Informationen zum erstmaligen Login
      </button>
      <button
        type="submit"
        class="ui fluid teal button centered button_margin_bottom"
      >
        Login
      </button>

      <div
        v-if="loginFailure"
        class="ui error message"
      >
        <div class="header">
          Login fehlgeschlagen
        </div>
        <p>Ihre Eingaben waren fehlerhaft, bitte versuchen Sie es erneut.</p>
      </div>

      <div
        v-if="forgotPasswordSuccess"
        class="ui success message"
      >
        <p>
          Wenn die E-Mail-Adresse in der MSD-Portal Datenbank Ihrer Schule existiert,
          haben wir Ihnen einen Link zum Zur&uuml;cksetzen Ihres Passworts geschickt.<br>
          Falls Sie keine E-Mail erhalten haben, schauen Sie bitte im Spam-Ordner nach
          und &uuml;berpr&uuml;fen Sie, ob die E-Mail-Adresse korrekt ist.
        </p>
      </div>
    </form>
  </div>
  <div
    v-if="currentVersion"
    class="text_center_opacity_06"
  >
    Version {{ currentVersion }}
  </div>
  <div class="flexbox-center-horizontally">
    <img
      class="antago-certificate"
      src="/cert_zs4art59h007679.png"
      alt="Antago Zertifikat"
    >
  </div>
  <sui-modal v-model="firstLoginInformationModalOpen">
    <sui-modal-header>Erstmaliger Login</sui-modal-header>
    <sui-modal-content>
      <sui-modal-description>
        <p>
          Geben Sie beim erstmaligen Login bitte unter Passwort Ihr Einmalpasswort ein.<br>
          Als AnsprechpartnerIn von art soft and more für die Einrichtung des MSD-Portals Ihrer Schule
          erhalten Sie Ihr Einmalpasswort von art soft and more.<br>
          Alle anderen User erhalten Ihr Einmalpasswort von den AdministratorInnen Ihres MSD-Portals.
        </p>
      </sui-modal-description>
    </sui-modal-content>
    <sui-modal-actions>
      <sui-button
        type="button"
        @click="toggleFirstLoginInformationModal"
      >
        schließen
      </sui-button>
    </sui-modal-actions>
  </sui-modal>

  <sui-modal v-model="forgotPasswordModalOpen">
    <sui-modal-header>Passwort zurücksetzen</sui-modal-header>
    <sui-modal-content>
      <sui-modal-description>
        <p>
          Sie haben Ihr Passwort vergessen? - Kein Problem!
        </p>
        <p>
          Wir schicken Ihnen einen Link, mit dem Sie Ihr Passwort zurücksetzen können.
          Geben Sie einfach Ihre E-Mail-Adresse und Ihr Schulkürzel ein und klicken Sie auf Link versenden.
        </p>
      </sui-modal-description>

      <form
        id="reset-password-form"
        class="ui form error"
        @submit.prevent="forgotPassword"
      >
        <div
          class="field"
        >
          <label>E-Mail</label>
          <input
            v-model="loginForm.email"
            type="email"
            required
            placeholder="z.B. john@doe.com"
          >
        </div>
        <div
          class="field"
        >
          <label>Schulk&uuml;rzel</label>
          <input
            v-model="schoolInputPasswordReset"
            type="text"
            required
            placeholder="z.B. demosfz"
          >
        </div>
      </form>
    </sui-modal-content>
    <sui-modal-actions>
      <sui-button
        type="button"
        @click="toggleResetPasswordModal"
      >
        abbrechen
      </sui-button>
      <sui-button
        type="button"
        positive
        @click="forgotPassword"
      >
        Link versenden
      </sui-button>
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
import store from "../store";

export default {
  name: 'LoginView',
  data: function () {
    return {
      loginForm: {
        email: null,
        password: null
      },
      schoolInput: null,
      schoolInputPasswordReset: null,
      loginFailure: false,
      forgotPasswordModalOpen: false,
      firstLoginInformationModalOpen: false,
      forgotPasswordSuccess: false
    }
  },
  computed: {
    schoolExists() {
      // In ResetPasswort when the user tries to switch to a school that doesn't exit
      // and then closes and opens the browser 'null' as string is written into the school state. I don't know why.
      return store.state.auth.school !== null && store.state.auth.school !== 'null';
    },
    currentVersion() {
      if (store.state.changelogModule.currentVersion) {
        return store.state.changelogModule.currentVersion;
      }
      else {
        return "";
      }
    }
  },
  methods: {
    login: function () {
      if (this.schoolInput) {
        store.dispatch('auth/getInitData', this.schoolInput)
        .then(() => {
          store.dispatch('auth/setSchool', this.schoolInput)
          .then(() => {
            store.dispatch('auth/claimCsrfToken')
            .then(() => {
              store.dispatch('auth/login', this.loginForm)
              .then(() => {
              })
              .catch(() => {
                this.loginFailure = true;
                store.dispatch('auth/setSchool', null);
              });
            })
            .catch(() => {
              this.loginFailure = true;
              store.dispatch('auth/setSchool', null);
            });
          })
          .catch(() => {
            alert('Es ist ein Fehler beim Login aufgetreten. Bitte wenden Sie sich an die/den AdministratorIn.');
          });
        })
        .catch(() => {
          alert('Es ist ein Fehler beim Abfragen der Schulart aus der Datenbank aufgetreten. Bitte wenden Sie sich an die/den AdministratorIn.');
        })
      }
      else {
        // There is no school input, because the school still exists in the cookie
        store.dispatch('auth/getInitData', null)
        .then(() => {
          store.dispatch('auth/claimCsrfToken')
          .then(() => {
            store.dispatch('auth/login', this.loginForm)
            .then(() => {
            })
            .catch(() => {
              this.loginFailure = true;
            });
          })
          .catch(() => {
            this.loginFailure = true;
          });
        })
        .catch(() => {
          alert('Es ist ein Fehler beim Abfragen der Schulart aus der Datenbank aufgetreten. Bitte wenden Sie sich an die/den AdministratorIn.');
        })
      }
    },
    toggleResetPasswordModal: function () {
      this.forgotPasswordModalOpen = !this.forgotPasswordModalOpen;
      this.loginForm.email = null;
      this.schoolInputPasswordReset = null;
    },
    toggleFirstLoginInformationModal: function () {
      this.firstLoginInformationModalOpen = !this.firstLoginInformationModalOpen;
    },
    forgotPassword: function () {
      this.forgotPasswordSuccess = false;

      if (this.schoolInputPasswordReset && this.loginForm.email) {
        store.dispatch('auth/setSchool', this.schoolInputPasswordReset)
        .then(() => {
          store.dispatch('auth/claimCsrfToken')
          .then(() => {
            let loginFormEmail = JSON.parse(JSON.stringify(this.loginForm.email));
            this.toggleResetPasswordModal();
            this.forgotPasswordSuccess = true;
            store.dispatch('auth/forgotPassword', loginFormEmail)
            .then(() => {
            })
            .catch(() => {
              store.dispatch('auth/setSchool', null); 
              alert('Fehler bei der Anfrage zum Zurücksetzen des Passworts. Bitte versuchen Sie es erneut. Falls es dann immer noch nicht funktioniert, wenden Sie sich bitte an die/den AdministratorIn.')
            });
          })
          .catch(() => {
            store.dispatch('auth/setSchool', null);
            alert('Bitte überprüfen Sie, ob Schulkürzel und E-Mail-Adresse korrekt sind.');
          });
        })
        .catch(() => {
          store.dispatch('auth/setSchool', null);
          alert('Es ist ein Fehler beim Zurücksetzen des Passworts aufgetreten. Bitte wenden Sie sich an die/den AdministratorIn.');
        });
      }
      else if (!this.schoolInputPasswordReset) {
        store.dispatch('auth/setSchool', null);
        alert('Bitte geben Sie ein Schulkürzel ein.');
      }
      else {
        store.dispatch('auth/setSchool', null);
        alert('Bitte geben Sie eine E-Mail-Adresse ein.');
      }
    }
  }
}
</script>

<style scoped>

</style>