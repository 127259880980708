import axios from 'axios';
import router from '../../router'

let allAntragItems = [];
let allFoerderplanItems = [];
let allGutachtenItems = [];
let allDiagnostischItems = [];
let allBetreuungsverlaufItems = [];
let allProtokolleItems = [];
let allBeobachtungenItems = [];
let allTherapieItems = [];
let antragCategories = [];
let foerderplanCategories = [];
let gutachtenCategories = [];

export default {
  namespaced: true,
  state: {
    // The betreuungsaktFunctionConfig is defined in BetreuungsaktFunctionDefinition.php
    betreuungsaktFunctionConfig: {},
    betreuungsaktFunctionsWithEntries: [],
    betreuungsakt_function_entry_id: null,
    allListItems: {
      "antrag": allAntragItems,
      "foerderplan": allFoerderplanItems,
      "gutachten": allGutachtenItems,
      "diagnostisch": allDiagnostischItems,
      "betreuungsverlauf": allBetreuungsverlaufItems,
      "protokolle": allProtokolleItems,
      "beobachtungen": allBeobachtungenItems,
      "therapie": allTherapieItems,
    },
    fileCategories: {
      "antrag": antragCategories,
      "foerderplan": foerderplanCategories,
      "gutachten": gutachtenCategories
    },
    oldFiles: [],
    copyOfEntryForAutosave: {},
    modalFileNoLongerExistsInStorage: null,
    modalFileWasDeletedByOtherUser: null,
    sortByEntriesWithDuplicateFileNames: false,
  },
  getters: {
    getBetreuungsaktFunctionConfig: (state) => state.betreuungsaktFunctionConfig,
    getBetreuungsaktFunctionFullName: (state) => (betreuungsaktFunction) => {
        if (state.betreuungsaktFunctionConfig && state.betreuungsaktFunctionConfig[betreuungsaktFunction]) {
            return state.betreuungsaktFunctionConfig[betreuungsaktFunction].fullName;
        }
        return null;
    },
    getBetreuungsaktFunctionsWithEntries: (state) => state.betreuungsaktFunctionsWithEntries,
    // Getters for checking properties of Betreuungsakt function
    isPropertyTrue: (state) => (betreuungsaktFunction, property) => state.betreuungsaktFunctionConfig?.[betreuungsaktFunction]?.properties?.[property] === true,
    canExpandItsEntries: (state, getters) => (betreuungsaktFunction) => getters.isPropertyTrue(betreuungsaktFunction, 'canExpandItsEntries'),
    hasCategories: (state, getters) => (betreuungsaktFunction) => getters.isPropertyTrue(betreuungsaktFunction, 'hasCategories'),
    hasDate: (state, getters) => (betreuungsaktFunction) => getters.isPropertyTrue(betreuungsaktFunction, 'hasDate'),
    hasDescription: (state, getters) => (betreuungsaktFunction) => getters.isPropertyTrue(betreuungsaktFunction, 'hasDescription'),
    sortListItemsState: (state) => (selected_betreuungsakt_function, sort_order, list_item_property, is_category) => {
      let allListItemsSorted = state.allListItems[selected_betreuungsakt_function];

      if (list_item_property === 'duplicate_file_names'){
        state.sortByEntriesWithDuplicateFileNames = true;
      }
      else {
        state.sortByEntriesWithDuplicateFileNames = false;
      }

      function propComparator(order, prop, is_category) {
        return function(a, b) {

          if (prop === 'updated_at'){
            if (order === "desc") {
              return new Date(b[prop]) - new Date(a[prop])
            }
            else {
              return new Date(a[prop]) - new Date(b[prop])
            }
          }
          else if (prop === 'duplicate_file_names'){
            return (a[prop] === b[prop])? 0 : b[prop]? 1 : -1;
          }
          else {
            var a_lowerCase = "";
            var b_lowerCase = "";

            if (typeof is_category !== 'undefined') {
              a_lowerCase = a['category'][prop].toLowerCase();
              b_lowerCase = b['category'][prop].toLowerCase();
            }
            else {
              a_lowerCase = a[prop] ? a[prop].toLowerCase() : "";
              b_lowerCase = b[prop] ? b[prop].toLowerCase() : "";
            }

            if (order === "desc") {
              return b_lowerCase.localeCompare(a_lowerCase, 'de', {sensitivity: 'base'})
            }
            else {
              return a_lowerCase.localeCompare(b_lowerCase, 'de', {sensitivity: 'base'})
            }
          }
        }
      }

      allListItemsSorted.sort(propComparator(sort_order, list_item_property, is_category));

    }
  },
  mutations: {
    setAllAntragItems(state, allAntragItems) {
      state.allListItems["antrag"] = allAntragItems;
    },
    setAllFoerderplanItems(state, allFoerderplanItems) {
      state.allListItems["foerderplan"] = allFoerderplanItems;
    },
    setAllGutachtenItems(state, allGutachtenItems) {
      state.allListItems["gutachten"] = allGutachtenItems;
    },
    setAllDiagnostischItems(state, allDiagnostischItems) {
      state.allListItems["diagnostisch"] = allDiagnostischItems;
    },
    setAllBetreuungsverlaufItems(state, allBetreuungsverlaufItems) {
      state.allListItems["betreuungsverlauf"] = allBetreuungsverlaufItems;
    },
    setAllProtokolleItems(state, allProtokolleItems) {
      state.allListItems["protokolle"] = allProtokolleItems;
    },
    setAllBeobachtungenItems(state, allBeobachtungenItems) {
      state.allListItems["beobachtungen"] = allBeobachtungenItems;
    },
    setAllTherapieItems(state, allTherapieItems) {
      state.allListItems["therapie"] = allTherapieItems;
    },
    setBetreuungsaktFunctionConfig(state, betreuungsaktFunctionConfig) {
      state.betreuungsaktFunctionConfig = betreuungsaktFunctionConfig;
    },
    setBetreuungsaktFunctionEntryId(state, betreuungsakt_function_entry_id) {
      state.betreuungsakt_function_entry_id = betreuungsakt_function_entry_id;
    },
    setBetreuungsaktFunctionsWithEntries(state, betreuungsaktFunctionsWithEntries) {
      state.betreuungsaktFunctionsWithEntries = betreuungsaktFunctionsWithEntries;
    },
    setAntragCategories(state, antragCategories) {
      state.fileCategories["antrag"] = antragCategories;
    },
    setFoerderplanCategories(state, foerderplanCategories) {
      state.fileCategories["foerderplan"] = foerderplanCategories;
    },
    setGutachtenCategories(state, gutachtenCategories) {
      state.fileCategories["gutachten"] = gutachtenCategories;
    },
    setOldFiles(state, old_files) {
      old_files.forEach(function (e) {
        let file_name_arr = e.original_file_name.split('.');
        e.original_file_name_file_extension = file_name_arr.pop();
        e.original_file_name_file_name = file_name_arr.join('.');
      })
      state.oldFiles = old_files;
    },
    setCopyOfEntryForAutosave(state, copyOfEntryForAutosave) {
      state.copyOfEntryForAutosave = copyOfEntryForAutosave;
    },
    setModalFileNoLongerExistsInStorage(state, modalFileNoLongerExistsInStorage) {
      state.modalFileNoLongerExistsInStorage = modalFileNoLongerExistsInStorage;
    },
    setModalFileWasDeletedByOtherUser(state, modalFileWasDeletedByOtherUser) {
      state.modalFileWasDeletedByOtherUser = modalFileWasDeletedByOtherUser;
    },
    setModalPostRequestLimitExceeded(state, modalPostRequestLimitExceeded) {
      state.modalPostRequestLimitExceeded = modalPostRequestLimitExceeded;
    },
    deleteListItemFromList(state, payload){
     var index = state.allListItems[payload.selectedBetreuungsaktFunction].findIndex(listItem => listItem.id === payload.betreuungsakt_function_entry_id)
     if (index !== -1){
        state.allListItems[payload.selectedBetreuungsaktFunction].splice(index, 1);
     }
    },
    deleteOldFile(state, payload){
      for (var i = state.oldFiles.length - 1; i >= 0; --i) {
          if (state.oldFiles[i].id === payload.fileToDeleteId) {
              state.oldFiles.splice(i,1);
          }
      }
    }
  },
  actions: {
    createNewListItem({commit, rootGetters, rootState}, payload) {
      commit('setLoading', true, {root: true});
      const data = new FormData();
      for (let i = 0; i < payload.file.length; i++) {
        data.append('file_'+i, payload.file[i]);
      }
      data.append('betreuungsakt_function', payload.selectedBetreuungsaktFunction);
      data.append('user_id', rootState.auth.user.id);
      if (payload.categoryName){
        data.append('category_name', payload.categoryName);
        data.append('is_main_category', payload.isMainCategory);
      }
      if (payload.heading){
        data.append('heading', payload.heading);
      }
      if (payload.date){
        data.append('date', payload.date);
      }
      data.append('description', payload.description);
      data.append('autosave', payload.autosave);
      return new Promise((resolve, reject) => {
        axios.post(rootGetters.getUrl('/api/schueler/' + payload.schueler_id + '/' + payload.selectedBetreuungsaktFunction), data)
          .then((res) => {
            commit('setLoading', false, {root: true});
            resolve(res);
            router.push('/schueler/' + payload.schueler_id + '/betreuungsakt/' + payload.selectedBetreuungsaktFunction);
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    updateAllListItems({commit, rootGetters}, payload){
      let selectedBetreuungsaktFunctionCapitalized = payload.selectedBetreuungsaktFunction[0].toUpperCase() + payload.selectedBetreuungsaktFunction.slice(1);
      return new Promise((resolve, reject) => {
        axios.get(rootGetters.getUrl('/api/schueler/' + payload.schueler_id + '/index/' + payload.selectedBetreuungsaktFunction))
          .then((res) => {
            commit('setAll' + selectedBetreuungsaktFunctionCapitalized + 'Items', res.data);
            resolve(res);
          })
          .catch((error) => {
            commit('setAll' + selectedBetreuungsaktFunctionCapitalized + 'Items', null);
            reject(error);
          });
      });
    },
    saveListItemChanges({commit, state, rootGetters, rootState}, payload){
      commit('setLoading', true, {root: true});
      const data = new FormData();
      for (let i = 0; i < payload.file.length; i++) {
        data.append('file_'+i, payload.file[i])
      }
      if (state.oldFiles.length){
        for (let i = 0; i < state.oldFiles.length; i++) {
          data.append('old_files[]', JSON.stringify(state.oldFiles[i]));
        }
      }
      data.append('betreuungsakt_function', payload.selectedBetreuungsaktFunction);
      if (payload.created_by_user_id) {
        data.append('created_by_user_id', payload.created_by_user_id);
      }
      data.append('last_modified_by_user_id', payload.last_modified_by_user_id ?? rootState.auth.user.id);
      data.append('description', payload.description);
      if (payload.categoryName){
        data.append('category_name', payload.categoryName);
        data.append('is_main_category', payload.isMainCategory);
      }
      if (payload.heading){
        data.append('heading', payload.heading);
      }
      if (payload.date){
        data.append('date', payload.date);
      }
      if (payload.lockVersion){
        data.append('lockVersion', payload.lockVersion);
      }
      if (payload.updated_at){
        data.append('updated_at', payload.updated_at);
      }
      data.append('autosave', payload.autosave);
      return new Promise((resolve, reject) => {
        axios.post(rootGetters.getUrl('/api/schueler/' + payload.schueler_id + '/' + payload.selectedBetreuungsaktFunction + '/' + state.betreuungsakt_function_entry_id), data)
          .then((res) => {
            commit('setLoading', false, {root: true});
            resolve(res);
            router.push('/schueler/' + payload.schueler_id + '/betreuungsakt/' + payload.selectedBetreuungsaktFunction);
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    getFileCategories({commit, rootGetters}, payload){
      let selectedBetreuungsaktFunctionCapitalized = payload.selectedBetreuungsaktFunction[0].toUpperCase() + payload.selectedBetreuungsaktFunction.slice(1);
      commit('setLoading', true, {root: true});
      axios.get(rootGetters.getUrl('/api/categories/' + payload.selectedBetreuungsaktFunction))
        .then((res) => {
          commit('set' + selectedBetreuungsaktFunctionCapitalized + 'Categories', res.data);
          commit('setLoading', false, {root: true});
        })
        .catch(() => {
          commit('set' + selectedBetreuungsaktFunctionCapitalized + 'Categories', null);
          commit('setLoading', false, {root: true});
        });
    },
    getAllListItems({commit, rootGetters}, payload){
      let selectedBetreuungsaktFunctionCapitalized = payload.selectedBetreuungsaktFunction[0].toUpperCase() + payload.selectedBetreuungsaktFunction.slice(1);
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.get(rootGetters.getUrl('/api/schueler/' + payload.schueler_id + '/index/' + payload.selectedBetreuungsaktFunction))
          .then((res) => {
            commit('setAll' + selectedBetreuungsaktFunctionCapitalized + 'Items', res.data);
            commit('setLoading', false, {root: true});
            resolve(res);
          })
          .catch((error) => {
            commit('setAll' + selectedBetreuungsaktFunctionCapitalized + 'Items', null);
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    deleteListItem({commit, state, rootGetters, rootState}, payload) {
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.delete(rootGetters.getUrl('/api/schueler/' + rootState.schuelerModule.schueler.id + '/' + payload.selectedBetreuungsaktFunction + '/' + state.betreuungsakt_function_entry_id))
          .then((res) => {
            commit('setLoading', false, {root: true});
            // For the school type GY this is not needed, because the entries are fetched from the db again at this point (FLOG - 15.11.2023)
            if (this.state.auth.schoolType === "SFZ")
            {
              commit('deleteListItemFromList', { betreuungsakt_function_entry_id: state.betreuungsakt_function_entry_id, selectedBetreuungsaktFunction: payload.selectedBetreuungsaktFunction });
            }
            commit('setBetreuungsaktFunctionEntryId', null);
            resolve(res);
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            commit('setBetreuungsaktFunctionEntryId', null);
            reject(error);
          });
      });
    },
    setBetreuungsaktFunctionEntryId({commit}, payload) {
        commit('setBetreuungsaktFunctionEntryId', payload.betreuungsakt_function_entry_id);
    },
    setOldFiles({commit}, payload) {
        commit('setOldFiles', payload.old_files);
    },
    setCopyOfEntryForAutosave({commit}, payload) {
        commit('setCopyOfEntryForAutosave', payload.copyOfEntryForAutosave);
    },
    setModalFileNoLongerExistsInStorage({commit}, payload) {
        commit('setModalFileNoLongerExistsInStorage', payload.modalFileNoLongerExistsInStorage);
    },
    setModalFileWasDeletedByOtherUser({commit}, payload) {
        commit('setModalFileWasDeletedByOtherUser', payload.modalFileWasDeletedByOtherUser);
    },
    getBetreuungsaktFunctionsWithEntries({commit, rootGetters, rootState}){
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.get(rootGetters.getUrl('/api/schueler/' + rootState.exportBetreuungsaktModule.betreuungsaktExportStudentId + '/get-betreuungsakt-functions-with-entries'))
          .then((res) => {
            commit('setLoading', false, {root: true});
            commit('setBetreuungsaktFunctionsWithEntries', res.data);
            resolve(res);
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    downloadFile({ rootGetters }, payload) {
      const { apiRoute, additionalParams = {} } = payload;

      return new Promise((resolve, reject) => {
        axios.post(rootGetters.getUrl(apiRoute), { additionalParams }, {
          responseType: 'blob',
        })
          .then((response) => {
            // Extract the filename from content-disposition
            const contentDisposition = response.headers['content-disposition'];
            let fileName = '';

            // Check for filename*=utf-8'' pattern
            const utf8FileNameMatch = contentDisposition.match(/filename\*=utf-8''([^;]+)/);
            if (utf8FileNameMatch) {
                // Extract and decode the filename from filename*=utf-8''
                const encodedFileName = utf8FileNameMatch[1];
                fileName = decodeURIComponent(encodedFileName);
            } else {
                // Check for filename= pattern
                const fileNameMatch = contentDisposition.match(/filename=([^;]+)/);
                if (fileNameMatch) {
                    // Extract the filename from filename=
                    fileName = fileNameMatch[1];
                } else {
                    alert('Fehler beim Herunterladen der Datei. Bitte wenden Sie sich an eine Administratorin / einen Administrator Ihres MSD-Portals.');
                }
            }

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            resolve(response);
          })
          .catch((error) => {
            /*
             * The error response is returned as a blob and is thus provided as binary data.
             * The file reader is therefore used here to read this binary data and convert it into readable text,
             * which can then be parsed as JSON to extract the actual error message.
             */
            if (error.response && error.response.data) {
              const reader = new FileReader();
              reader.onload = function() {
                try {
                  const errorData = JSON.parse(reader.result);
                  reject(new Error(errorData.error));
                } catch (e) {
                  reject(new Error('Error parsing the error response'));
                }
              };
              reader.onerror = function() {
                reject(new Error('Error reading the error response'));
              };
              reader.readAsText(error.response.data);
            } else {
              reject(error);
            }
          });
      });
    }
  },
  modules: {}
}