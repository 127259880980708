<template>
  <h4 class="centered margins no-margin-bottom margin-top-large">
    Administration
  </h4>
  <div
    v-if="schoolType === 'SFZ'"
    class="ui centered two column grid"
  >
    <div class="row">
      <router-link
        is="column"
        :to="'/administration/all-users'"
        class="ui center aligned segment margins margin-top blue_widget"
        active-class="active"
      >
        <sui-icon
          name="users"
          size="big"
          class="icon_widget"
        />
        <p>
          User-<br>verwaltung
        </p>
      </router-link>
      <router-link
        is="column"
        :to="'/administration/schueler-user-assign'"
        class="ui center aligned segment margins margin-top orange_widget"
        active-class="active"
        style="padding-left: 0.5em; padding-right: 0.5em"
      >
        <sui-icon
          name="hands helping"
          size="big"
          class="icon_widget"
        />
        <p>
          SchülerInnen User zuordnen
        </p>
      </router-link>
    </div>
    <div class="row">
      <router-link
        is="column"
        :to="'/administration/choose-categories'"
        class="ui center aligned segment margins margin-top red_widget"
        active-class="active"
      >
        <sui-icon
          name="tags"
          size="big"
          class="icon_widget"
        />
        <p>
          Kategorien<br>anlegen
        </p>
      </router-link>
      <router-link
        is="column"
        :to="'/administration'"
        class="ui center aligned segment margins margin-top green_widget"
        active-class="active"
        @click="exportStatistics()"
      >
        <sui-icon
          name="file excel"
          size="big"
          class="icon_widget"
        />
        <p>
          Export für<br>die Statistik
        </p>
      </router-link>
    </div>
    <div class="row">
      <router-link
        is="column"
        :to="'/administration/admin-log'"
        class="ui center aligned segment margins margin-top yellow_widget"
        active-class="active"
      >
        <sui-icon
          name="user clock"
          size="big"
          class="icon_widget"
        />
        <p>
          Admin-<br>Log
        </p>
      </router-link>
      <router-link
        is="column"
        :to="'/administration'"
        class="ui center aligned segment margins margin-top transparent_widget"
        active-class="active"
      >
      </router-link>
    </div>
  </div>
  <div
    v-else
    class="ui centered two column grid"
  >
    <div class="row">
      <router-link
        is="column"
        :to="'/administration/all-users'"
        class="ui center aligned segment margins margin-top blue_widget"
        active-class="active"
      >
        <sui-icon
          name="users"
          size="big"
          class="icon_widget"
        />
        <p>
          User-<br>verwaltung
        </p>
      </router-link>
      <router-link
        is="column"
        :to="'/administration/choose-categories'"
        class="ui center aligned segment margins margin-top orange_widget"
        active-class="active"
      >
        <sui-icon
          name="tags"
          size="big"
          class="icon_widget"
        />
        <p>
          Kategorien<br>anlegen
        </p>
      </router-link>
    </div>
    <div class="row">
      <router-link
        is="column"
        :to="'/administration'"
        class="ui center aligned segment margins margin-top red_widget"
        active-class="active"
        @click="exportStatistics()"
      >
        <sui-icon
          name="file excel"
          size="big"
          class="icon_widget"
        />
        <p>
          Export für<br>die Statistik
        </p>
      </router-link>
      <router-link
        is="column"
        :to="'/administration/admin-log'"
        class="ui center aligned segment margins margin-top green_widget"
        active-class="active"
      >
        <sui-icon
          name="user clock"
          size="big"
          class="icon_widget"
        />
        <p>
          Admin-<br>Log
        </p>
      </router-link>
    </div>
  </div>
  <BaseConfirmModal
    v-model="requestReturnedForbiddenResponseStatus"
    header="Fehlende Berechtigung"
  >
    <template #modalText>
      Sie haben gerade versucht eine Aktion durchzuf&uuml;hren, für die Sie nicht die n&ouml;tigen Rechte besitzen.<br>
      M&ouml;glicherweise wurde von einem User mit Administrationszugang Ihre Userrolle ge&auml;ndert und damit haben sich Ihre Rechte ver&auml;ndert.<br>
      Bei Fragen wenden Sie sich bitte an die AdministratorInnen des MSD-Portals Ihrer Schule.<br>
      Sie wurden zum Administrations-Bereich weitergeleitet.
    </template>
    <template #cancelButton>
      <sui-button @click="closeRequestReturnedForbiddenResponseStatusMessage()">
        schließen
      </sui-button>
    </template>
  </BaseConfirmModal>
</template>

<script>
import store from "../store";
import BaseConfirmModal from "../components/BaseConfirmModal";

export default {
  name: "AdministrationMenu",
  components: {BaseConfirmModal},
  computed: {
    requestReturnedForbiddenResponseStatus() {
      return store.state.config.requestReturnedForbiddenResponseStatus;
    },
    schoolType() {
      return store.state.auth.schoolType;
    }
  },
  methods: {
    closeRequestReturnedForbiddenResponseStatusMessage: function() {
      store.dispatch('config/setRequestReturnedForbiddenResponseStatus', { requestReturnedForbiddenResponseStatus : false });
    },
    exportStatistics: function () {
      store.dispatch('filesModule/downloadFile', { apiRoute: '/admin/export_statistics' })
        .catch(() => {
          alert('Fehler beim Erstellen der CSV-Datei für die Statistik. Bitte wenden Sie sich an den Administrator.')
        });
    }
  }
}
</script>

<style scoped>
</style>
