import axios from 'axios';

export default {
  namespaced: true,
  state: {
    betreuungsaktExportStudentId: null,
    betreuungsaktExportStudentName: null,
    betreuungsaktExportStudentVorname: null,
    categoriesWithDuplicateFileNames: [],
    pupilHasNoEntries: false,
  },
  getters: {
    getBetreuungsaktExportStudentId: (state) => state.betreuungsaktExportStudentId,
    getBetreuungsaktExportStudentName: (state) => state.betreuungsaktExportStudentName,
    getBetreuungsaktExportStudentVorname: (state) => state.betreuungsaktExportStudentVorname,
  },
  mutations: {
    clearBetreuungsaktExportStudentData(state) {
      state.betreuungsaktExportStudentId = null;
      state.betreuungsaktExportStudentName = null;
      state.betreuungsaktExportStudentVorname = null;
    },
    setBetreuungsaktExportStudentData(state, {betreuungsaktExportStudentId, betreuungsaktExportStudentName, betreuungsaktExportStudentVorname}) {
      state.betreuungsaktExportStudentId = betreuungsaktExportStudentId;
      state.betreuungsaktExportStudentName = betreuungsaktExportStudentName;
      state.betreuungsaktExportStudentVorname = betreuungsaktExportStudentVorname;
    },
    setCategoriesWithDuplicateFileNames(state, categoriesWithDuplicateFileNames) {
      state.categoriesWithDuplicateFileNames = categoriesWithDuplicateFileNames;
    },
    setPupilHasNoEntries(state, pupil_has_no_entries) {
      state.pupilHasNoEntries = pupil_has_no_entries;
    },
  },
  actions: {
    checkForDuplicateFiles({commit, state, dispatch, rootGetters}){
      dispatch('setPupilHasNoEntries', { pupilHasNoEntries: false });
      dispatch('setCategoriesWithDuplicateFileNames', { categoriesWithDuplicateFileNames: [] });
      commit('setLoading', true, {root: true});
      return new Promise((resolve, reject) => {
        axios.get(rootGetters.getUrl('/api/schueler/' + state.betreuungsaktExportStudentId + '/check-for-duplicate-file-names'))
          .then((res) => {
            commit('setLoading', false, {root: true});
            if (Object.keys(res.data).length === 1 && res.data[0] === 'pupil has no entries')
            {
              dispatch('setPupilHasNoEntries', { pupilHasNoEntries: true });
            }
            else if (Object.keys(res.data).length){
              dispatch('setCategoriesWithDuplicateFileNames', { categoriesWithDuplicateFileNames: res.data });
            }
            resolve(res);
          })
          .catch((error) => {
            commit('setLoading', false, {root: true});
            reject(error);
          });
      });
    },
    setCategoriesWithDuplicateFileNames({commit}, payload) {
      commit('setCategoriesWithDuplicateFileNames', payload.categoriesWithDuplicateFileNames);
    },
    setPupilHasNoEntries({commit}, payload) {
      commit('setPupilHasNoEntries', payload.pupilHasNoEntries);
    },
  }
}
