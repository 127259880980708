
export default {
  namespaced: true,
  state: {
    BetreuungsaktExportConfigurationModal: false,
    BetreuungsaktExportStartedModal: false,
  },
  getters: {
    isModalOpen: (state) => (modal) => {
      return state[modal] || false;
    }
  },
  mutations: {
    setModalState(state, { modal, value }) {
      if (state[modal] !== undefined) {
        state[modal] = value;
      }
    }
  },
  actions: {
    openModal({ commit }, modal) {
      commit('setModalState', { modal, value: true });
    },
    closeModal({ commit }, modal) {
      commit('setModalState', { modal, value: false });
    }
  }
}
