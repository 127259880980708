import {createStore} from 'vuex'
import adminLogModule from './modules/adminLogModule';
import auth from './modules/auth'
import config from './modules/config';
import debugModule from './modules/debugModule';
import exportBetreuungsaktModule from './modules/exportBetreuungsaktModule';
import schuelerModule from './modules/schuelerModule';
import allSchuelerModule from './modules/allSchuelerModule';
import filesModule from './modules/filesModule';
import editUserModule from './modules/editUserModule';
import allUsersModule from './modules/allUsersModule';
import chooseCategoriesModule from './modules/chooseCategoriesModule';
import changelogModule from './modules/changelogModule';
import assignUsersToStudentGYModule from './modules/assignUsersToStudentGYModule';
import modalsModule from "./modules/modalsModule";

export default createStore({
  state: {
    loading: {
      count: 0,
      active: false
    }
  },
  getters: {
    getUrl: (state) => (uri) => {
      return state.config.serverUrl + uri;
    }
  },
  mutations: {
    setLoading(state, loading) {
      if (loading) {
        state.loading.count++;
      } else {
        state.loading.count--;
      }
      state.loading.active = state.loading.count > 0;
    }
  },
  actions: {},
  modules: {
    adminLogModule: adminLogModule,
    auth: auth,
    config: config,
    debugModule: debugModule,
    exportBetreuungsaktModule: exportBetreuungsaktModule,
    schuelerModule: schuelerModule,
    allSchuelerModule: allSchuelerModule,
    filesModule: filesModule,
    editUserModule: editUserModule,
    allUsersModule: allUsersModule,
    chooseCategoriesModule: chooseCategoriesModule,
    changelogModule: changelogModule,
    assignUsersToStudentGYModule: assignUsersToStudentGYModule,
    modalsModule: modalsModule
  }
})
